import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';

const CyberSecurity = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/cyber`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [

    {
      field: 'testName',
      headerName: 'Test Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'sessionName',
      headerName: 'Session Name',
      flex: 1,
    },
    {
      field: 'urlweb',
      headerName: 'URL',
      flex: 1,
    },
    {
      field: 'useajax',
      headerName: 'Use AJAX',
      flex: 1,
    },
    {
      field: 'browser',
      headerName: 'Browser',
      flex: 1,
    },
  ];

  return (
    <Box m={'20px'}>
      <Header
        title={'PENETRATION TESTING'}
        subtitle={'Result Penetration Testing'}
      />
      <Box
        m={'40px 0 0 0 '}
        height={'75vh'}
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid rows={data} columns={columns} />
      </Box>
    </Box>
  );
};

export default CyberSecurity;

export const mockGeographyData = [
  {
    id: "ID-AC", // Aceh
    value: 490681,
    sentiment: "positive",
  },
  {
    id: "ID-SU", // Sumatera Utara
    value: 3702121,
    sentiment: "negative",
  },
  {
    id: "ID-SB", // Sumatera Barat
    value: 2894918,
    sentiment: "neutral",
  },
  // Data untuk kota/kabupaten lainnya di Indonesia
  {
    id: "ID-JK", // Jakarta
    value: 10325695,
    sentiment: "positive",
  },
  {
    id: "ID-JB", // Jawa Barat
    value: 9485200,
    sentiment: "neutral",
  },
  {
    id: "ID-JT", // Jawa Tengah
    value: 8253560,
    sentiment: "negative",
  },
  // Data untuk kota/kabupaten lainnya di Indonesia
  {
    id: "ID-JI", // Jawa Timur
    value: 9841560,
    sentiment: "positive",
  },
  {
    id: "ID-BT", // Banten
    value: 1064403,
    sentiment: "neutral",
  },
  // Tambahkan data untuk kota/kabupaten lainnya di Indonesia
];
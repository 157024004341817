import { ColorsModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Cyber from "./scenes/cyber";
import Geography from "./scenes/geography";
import InputResult from "./scenes/cyberInput"

function App() {
  const [theme, colorsMode] = useMode();

  return (
    <ColorsModeContext.Provider value={colorsMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/cybersecurity" element={<Cyber />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/inputresult" element={<InputResult />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorsModeContext.Provider>
  );
}

export default App;

import { ResponsiveChoropleth } from "@nivo/geo";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { geoFeatures } from "../data/mockGeoFeatures";
import { mockGeographyData as data } from "../data/mockData";

const GeographyChart = ({ isDashboard = false }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ResponsiveChoropleth
            data={data}
            features={geoFeatures.features}
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
            }}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            domain={[0, 1000000]}
            unknownColor="#666666"
            label="properties.name"
            valueFormat=".2s"
            projectionScale={isDashboard ? 600 : 1000}
            projectionTranslation={isDashboard ? [0.48, 0.5] : [-1.2, 0.3]}
            projectionRotation={[0, 0, 0]}
            borderWidth={0.5}
            borderColor="#ffffff"
            // legends={
            //     !isDashboard
            //     ? [
            //         {
            //             anchor: 'bottom-left',
            //             direction: 'column',
            //             justify: true,
            //             translateX: 20,
            //             translateY: -100,
            //             itemsSpacing: 0,
            //             itemWidth: 94,
            //             itemHeight: 18,
            //             itemDirection: 'left-to-right',
            //             itemTextColor: colors.grey[100],
            //             itemOpacity: 0.85,
            //             symbolSize: 18,
            //             effects: [
            //                 {
            //                     on: 'hover',
            //                     style: {
            //                         itemTextColor: '#ffffff',
            //                         itemOpacity: 1,
            //                     },
            //                 },
            //             ],
            //         },
            //     ]
            //     : undefined
            // }
        />
    );
};

export default GeographyChart;

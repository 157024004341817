import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Card, CardContent, Typography, useTheme, Select, MenuItem, FormControl, InputLabel, Pagination } from '@mui/material';
import Header from '../../components/Header';
import { ResponsivePie } from '@nivo/pie';
import { tokens } from '../../theme';

const SentimenAnalisis = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sentimentData, setSentimentData] = useState([]);
    const [filter, setFilter] = useState('all');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sentiment`);
            const fetchedData = await response.json();
            console.log('Fetched Data:', fetchedData);
            setData(fetchedData);
            setFilteredData(fetchedData);
            extractRegions(fetchedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []); 

    useEffect(() => {
        fetchData();
    }, [fetchData]); 

    useEffect(() => {
        let newData = data;

        if (selectedRegion !== '') {
            newData = newData.filter(item => item.region === selectedRegion);
        }

        if (filter !== 'all') {
            newData = newData.filter(item => item.sentiment === filter);
        }

        setFilteredData(newData);
        calculateSentimentData(newData);
        setPage(1);
    }, [selectedRegion, filter, data]);

    const extractRegions = (data) => {
        const uniqueRegions = [...new Set(data.map(item => item.region))];
        setRegions(uniqueRegions);
    };

    const calculateSentimentData = (data) => {
        if (!data.length) {
            console.log('No data to calculate sentiment');
            setSentimentData([]);
            return;
        }

        const sentimentCounts = data.reduce((acc, item) => {
            if (item.sentiment === 'neutral') {
                acc.neutral = (acc.neutral || 0) + 1;
            } else if (item.sentiment === 'positive') {
                acc.positive = (acc.positive || 0) + 1;
            } else if (item.sentiment === 'negative') {
                acc.negative = (acc.negative || 0) + 1;
            }
            return acc;
        }, {});

        console.log('Sentiment Counts:', sentimentCounts);

        const sentimentChartData = [
            { id: 'neutral', label: 'Neutral', value: sentimentCounts.neutral || 0 },
            { id: 'positive', label: 'Positive', value: sentimentCounts.positive || 0 },
            { id: 'negative', label: 'Negative', value: sentimentCounts.negative || 0 },
        ];

        console.log('Sentiment Chart Data:', sentimentChartData);
        setSentimentData(sentimentChartData);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleRegionChange = (event) => {
        setSelectedRegion(event.target.value);
    };

    const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <Box m={"20px"}>
            <Header title="Sentimen Analisis" subtitle="Analysis of Sentiment Data" />
            <FormControl fullWidth>
                <InputLabel id="region-label">Filter by Region</InputLabel>
                <Select
                    labelId="region-label"
                    value={selectedRegion}
                    label="Filter by Region"
                    onChange={handleRegionChange}
                >
                    <MenuItem value="">All Regions</MenuItem>
                    {regions.map(region => (
                        <MenuItem key={region} value={region}>{region}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="filter-label">Filter by Sentiment</InputLabel>
                <Select
                    labelId="filter-label"
                    value={filter}
                    label="Filter by Sentiment"
                    onChange={handleFilterChange}
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="neutral">Neutral</MenuItem>
                    <MenuItem value="positive">Positive</MenuItem>
                    <MenuItem value="negative">Negative</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                <Select
                    labelId="rows-per-page-label"
                    value={rowsPerPage}
                    label="Rows per page"
                    onChange={handleRowsPerPageChange}
                >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
            <Box height={400} mt={2}>
                <ResponsivePie
                    data={sentimentData}
                    theme={{
                        axis: {
                            domain: {
                                line: {
                                    stroke: colors.grey[100]
                                }
                            },
                            legend: {
                                text: {
                                    fill: colors.grey[100]
                                }
                            },
                            ticks: {
                                line: {
                                    stroke: colors.grey[100],
                                    strokeWidth: 1
                                },
                                text: {
                                    fill: colors.grey[100]
                                }
                            }
                        },
                        legends: {
                            text: {
                                fill: colors.grey[100]
                            }
                        },
                        tooltip: {
                            container: {
                                background: colors.grey[900], 
                                color: colors.grey[100],
                                fontSize: '12px',
                            },
                        },
                    }}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={({ id }) => {
                        switch (id) {
                            case 'neutral':
                                return '#FFFF00'; 
                            case 'positive':
                                return '#00FF00'; 
                            case 'negative':
                                return '#FF0000'; 
                            default:
                                return colors.grey[500] || '#666666'; 
                        }
                    }}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLinkLabelsTextColor={colors.grey[100]}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor={colors.grey[100]}
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor={colors.grey[100]}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor={colors.grey[500]} 
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            </Box>
            <Grid container spacing={2} p={2}>
                {paginatedData.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color={colors.grey[500] || '#e0e0e0'}>
                                    Timestamp: {item.timesAgo}
                                </Typography>
                                <Typography variant="body2" color={colors.grey[500] || '#e0e0e0'}>
                                    Sentiment: {item.sentiment}
                                </Typography>
                                <Typography variant="body2" color={colors.grey[500] || '#e0e0e0'}>
                                    Confidence: {item.confidence}%
                                </Typography>
                                <Typography variant="body2" color={colors.grey[500] || '#e0e0e0'}>
                                    Region: {item.region}
                                </Typography>
                                <Typography variant="body2" color={colors.grey[500] || '#e0e0e0'}>
                                    URL: {item.url}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={Math.ceil(filteredData.length / rowsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default SentimenAnalisis;

import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';

const Form = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const handleFormSubmit = async (values) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/cyber`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      alert('Data submitted successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit data.');
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE TEST" subtitle="Create a New Cybersecurity Test" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Test Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.testName}
                name="testName"
                error={!!touched.testName && !!errors.testName}
                helperText={touched.testName && errors.testName}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Session Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sessionName}
                name="sessionName"
                error={!!touched.sessionName && !!errors.sessionName}
                helperText={touched.sessionName && errors.sessionName}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.urlweb}
                name="urlweb"
                error={!!touched.urlweb && !!errors.urlweb}
                helperText={touched.urlweb && errors.urlweb}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Use AJAX"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.useajax}
                name="useajax"
                error={!!touched.useajax && !!errors.useajax}
                helperText={touched.useajax && errors.useajax}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Browser"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.browser}
                name="browser"
                error={!!touched.browser && !!errors.browser}
                helperText={touched.browser && errors.browser}
                sx={{ gridColumn: 'span 4' }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Test
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  testName: yup.string().required('required'),
  sessionName: yup.string().required('required'),
  urlweb: yup.string().url('invalid URL').required('required'),
  useajax: yup.string().required('required'),
  browser: yup.string().required('required'),
});

const initialValues = {
  testName: '',
  sessionName: '',
  urlweb: '',
  useajax: '',
  browser: '',
};

export default Form;
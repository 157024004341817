export const geoFeatures = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        name: "Indonesia",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [120.715609, -10.239581],
              [120.295014, -10.25865],
              [118.967808, -9.557969],
              [119.90031, -9.36134],
              [120.425756, -9.665921],
              [120.775502, -9.969675],
              [120.715609, -10.239581],
            ],
          ],
          [
            [
              [124.43595, -10.140001],
              [123.579982, -10.359987],
              [123.459989, -10.239995],
              [123.550009, -9.900016],
              [123.980009, -9.290027],
              [124.968682, -8.89279],
              [125.07002, -9.089987],
              [125.08852, -9.393173],
              [124.43595, -10.140001],
            ],
          ],
          [
            [
              [117.900018, -8.095681],
              [118.260616, -8.362383],
              [118.87846, -8.280683],
              [119.126507, -8.705825],
              [117.970402, -8.906639],
              [117.277731, -9.040895],
              [116.740141, -9.032937],
              [117.083737, -8.457158],
              [117.632024, -8.449303],
              [117.900018, -8.095681],
            ],
          ],
          [
            [
              [122.903537, -8.094234],
              [122.756983, -8.649808],
              [121.254491, -8.933666],
              [119.924391, -8.810418],
              [119.920929, -8.444859],
              [120.715092, -8.236965],
              [121.341669, -8.53674],
              [122.007365, -8.46062],
              [122.903537, -8.094234],
            ],
          ],
          [
            [
              [108.623479, -6.777674],
              [110.539227, -6.877358],
              [110.759576, -6.465186],
              [112.614811, -6.946036],
              [112.978768, -7.594213],
              [114.478935, -7.776528],
              [115.705527, -8.370807],
              [114.564511, -8.751817],
              [113.464734, -8.348947],
              [112.559672, -8.376181],
              [111.522061, -8.302129],
              [110.58615, -8.122605],
              [109.427667, -7.740664],
              [108.693655, -7.6416],
              [108.277763, -7.766657],
              [106.454102, -7.3549],
              [106.280624, -6.9249],
              [105.365486, -6.851416],
              [106.051646, -5.895919],
              [107.265009, -5.954985],
              [108.072091, -6.345762],
              [108.486846, -6.421985],
              [108.623479, -6.777674],
            ],
          ],
          [
            [
              [134.724624, -6.214401],
              [134.210134, -6.895238],
              [134.112776, -6.142467],
              [134.290336, -5.783058],
              [134.499625, -5.445042],
              [134.727002, -5.737582],
              [134.724624, -6.214401],
            ],
          ],
          [
            [
              [127.249215, -3.459065],
              [126.874923, -3.790983],
              [126.183802, -3.607376],
              [125.989034, -3.177273],
              [127.000651, -3.129318],
              [127.249215, -3.459065],
            ],
          ],
          [
            [
              [130.471344, -3.093764],
              [130.834836, -3.858472],
              [129.990547, -3.446301],
              [129.155249, -3.362637],
              [128.590684, -3.428679],
              [127.898891, -3.393436],
              [128.135879, -2.84365],
              [129.370998, -2.802154],
              [130.471344, -3.093764],
            ],
          ],
          [
            [
              [134.143368, -1.151867],
              [134.422627, -2.769185],
              [135.457603, -3.367753],
              [136.293314, -2.307042],
              [137.440738, -1.703513],
              [138.329727, -1.702686],
              [139.184921, -2.051296],
              [139.926684, -2.409052],
              [141.00021, -2.600151],
              [141.017057, -5.859022],
              [141.033852, -9.117893],
              [140.143415, -8.297168],
              [139.127767, -8.096043],
              [138.881477, -8.380935],
              [137.614474, -8.411683],
              [138.039099, -7.597882],
              [138.668621, -7.320225],
              [138.407914, -6.232849],
              [137.92784, -5.393366],
              [135.98925, -4.546544],
              [135.164598, -4.462931],
              [133.66288, -3.538853],
              [133.367705, -4.024819],
              [132.983956, -4.112979],
              [132.756941, -3.746283],
              [132.753789, -3.311787],
              [131.989804, -2.820551],
              [133.066845, -2.460418],
              [133.780031, -2.479848],
              [133.696212, -2.214542],
              [132.232373, -2.212526],
              [131.836222, -1.617162],
              [130.94284, -1.432522],
              [130.519558, -0.93772],
              [131.867538, -0.695461],
              [132.380116, -0.369538],
              [133.985548, -0.78021],
              [134.143368, -1.151867],
            ],
          ],
          [
            [
              [125.240501, 1.419836],
              [124.437035, 0.427881],
              [123.685505, 0.235593],
              [122.723083, 0.431137],
              [121.056725, 0.381217],
              [120.183083, 0.237247],
              [120.04087, -0.519658],
              [120.935905, -1.408906],
              [121.475821, -0.955962],
              [123.340565, -0.615673],
              [123.258399, -1.076213],
              [122.822715, -0.930951],
              [122.38853, -1.516858],
              [121.508274, -1.904483],
              [122.454572, -3.186058],
              [122.271896, -3.5295],
              [123.170963, -4.683693],
              [123.162333, -5.340604],
              [122.628515, -5.634591],
              [122.236394, -5.282933],
              [122.719569, -4.464172],
              [121.738234, -4.851331],
              [121.489463, -4.574553],
              [121.619171, -4.188478],
              [120.898182, -3.602105],
              [120.972389, -2.627643],
              [120.305453, -2.931604],
              [120.390047, -4.097579],
              [120.430717, -5.528241],
              [119.796543, -5.6734],
              [119.366906, -5.379878],
              [119.653606, -4.459417],
              [119.498835, -3.494412],
              [119.078344, -3.487022],
              [118.767769, -2.801999],
              [119.180974, -2.147104],
              [119.323394, -1.353147],
              [119.825999, 0.154254],
              [120.035702, 0.566477],
              [120.885779, 1.309223],
              [121.666817, 1.013944],
              [122.927567, 0.875192],
              [124.077522, 0.917102],
              [125.065989, 1.643259],
              [125.240501, 1.419836],
            ],
          ],
          [
            [
              [128.688249, 1.132386],
              [128.635952, 0.258486],
              [128.12017, 0.356413],
              [127.968034, -0.252077],
              [128.379999, -0.780004],
              [128.100016, -0.899996],
              [127.696475, -0.266598],
              [127.39949, 1.011722],
              [127.600512, 1.810691],
              [127.932378, 2.174596],
              [128.004156, 1.628531],
              [128.594559, 1.540811],
              [128.688249, 1.132386],
            ],
          ],
          [
            [
              [117.875627, 1.827641],
              [118.996747, 0.902219],
              [117.811858, 0.784242],
              [117.478339, 0.102475],
              [117.521644, -0.803723],
              [116.560048, -1.487661],
              [116.533797, -2.483517],
              [116.148084, -4.012726],
              [116.000858, -3.657037],
              [114.864803, -4.106984],
              [114.468652, -3.495704],
              [113.755672, -3.43917],
              [113.256994, -3.118776],
              [112.068126, -3.478392],
              [111.703291, -2.994442],
              [111.04824, -3.049426],
              [110.223846, -2.934032],
              [110.070936, -1.592874],
              [109.571948, -1.314907],
              [109.091874, -0.459507],
              [108.952658, 0.415375],
              [109.069136, 1.341934],
              [109.66326, 2.006467],
              [109.830227, 1.338136],
              [110.514061, 0.773131],
              [111.159138, 0.976478],
              [111.797548, 0.904441],
              [112.380252, 1.410121],
              [112.859809, 1.49779],
              [113.80585, 1.217549],
              [114.621355, 1.430688],
              [115.134037, 2.821482],
              [115.519078, 3.169238],
              [115.865517, 4.306559],
              [117.015214, 4.306094],
              [117.882035, 4.137551],
              [117.313232, 3.234428],
              [118.04833, 2.28769],
              [117.875627, 1.827641],
            ],
          ],
          [
            [
              [105.817655, -5.852356],
              [104.710384, -5.873285],
              [103.868213, -5.037315],
              [102.584261, -4.220259],
              [102.156173, -3.614146],
              [101.399113, -2.799777],
              [100.902503, -2.050262],
              [100.141981, -0.650348],
              [99.26374, 0.183142],
              [98.970011, 1.042882],
              [98.601351, 1.823507],
              [97.699598, 2.453184],
              [97.176942, 3.308791],
              [96.424017, 3.86886],
              [95.380876, 4.970782],
              [95.293026, 5.479821],
              [95.936863, 5.439513],
              [97.484882, 5.246321],
              [98.369169, 4.26837],
              [99.142559, 3.59035],
              [99.693998, 3.174329],
              [100.641434, 2.099381],
              [101.658012, 2.083697],
              [102.498271, 1.3987],
              [103.07684, 0.561361],
              [103.838396, 0.104542],
              [103.437645, -0.711946],
              [104.010789, -1.059212],
              [104.369991, -1.084843],
              [104.53949, -1.782372],
              [104.887893, -2.340425],
              [105.622111, -2.428844],
              [106.108593, -3.061777],
              [105.857446, -4.305525],
              [105.817655, -5.852356],
            ],
          ],
        ],
      },
      id: "IDN",
    },
  ],
};
